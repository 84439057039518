import React from "react";
import "../assets/css/formMessage.css";

export const FormMessage = ({ correctValidation }) => {
  return (
    <>
        <div>
          {correctValidation === 2 ? (
            <div
              className="telefono"
              role="alert"
            >
              Invalid phone number! Fix it and try again please
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 3 ? (
            <div
              className="mail"
              role="alert"
            >
              Invalid email! Fix it and try again please
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 4 ? (
            <div
              className="error-post"
              role="alert"
            >
              An unexpected error has occurred. Try it again please
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 5 ? (
            <div
              className="zip"
              role="alert"
            >
              Invalid Zip! Fix it and try again please
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 6 ? (
            <div
              className="name"
              role="alert"
            >
              URL Invalidad, Vuelva a ingresar una URL
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : null}
        </div>
    </>
  );
};
