import React from 'react'
import "./assets/css/App.css";
import { HashRouter, Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';

const App = () => {
  return (
    <div>
      <HashRouter>
          <Routes>
            <Route exact path='/' element={<HomeScreen/>}></Route>
            <Route exact path='/login' element={<LoginScreen/>}></Route>
            <Route exact path='/home' element={<HomeScreen/>}></Route>
          </Routes>
        </HashRouter>      
    </div>
  )
}

export default App