import React from "react";
import securityLogo from '../assets/img/master.svg'
import '../assets/css/logo.css'

export const Logo = () => {
  return (
      <div className="logo">
          <img className="logo-security" src={securityLogo} alt="logo" />        
      </div>
  );
};

