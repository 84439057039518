import React, { useState } from "react";
import { useForm } from "../hooks/useForm";
import "../assets/css/formAnalyzer.css";
import "../assets/css/Modal.css";
import { FormMessage } from "./FormMessage";
import axios from "axios";
import checkGreen from "../assets/img/circleCheckGreen.svg";
import { Modal } from './Modal';
import { useModal } from '../hooks/useModal';
import { ModalBody, ModalHeader } from 'reactstrap';

export const FormAnalyzer = () => {
  
  const initialForm = {
    url: "",
  };

  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const [correctValidation, setCorrectValidation] = useState(0);
  const [isOpenModal, openModal, closeModal] = useModal(false);
  const [loading, setLoading] = useState(false);
  const [response, setresponse] = useState({})
  const validationsForm = async (form) => {
    if (!form.url.trim()) {
      setCorrectValidation(6);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return false
    }
    return true
};

  // Función para transformar booleanos a "si" o "no"
  const booleanToText = (value) => (value ? 'si' : 'no');
  const handleSubmit = async (e) => {
    e.preventDefault(); // Evito que se recargue la pagina
    setLoading(true);
    const validation = validationsForm(formValues)

    let lead = {
      url: formValues.url
    };

    // Si todo esta correcto, lo envio
    if (
      validation
    )
    {
      await axios({
        method: "POST",
        url: "processor.php",
        headers: {
          "Content-Type": "application/json",
        },
        data: lead,
      })
        .then((res) => {
          if (res.status === 200) {
            openModal()
            setresponse(res.data)
            setLoading(false);
          }
        })
        .catch((err) => {
          setCorrectValidation(4); // "A ocurrido un error inesperado"
          setLoading(false);
          setTimeout(() => {
            setCorrectValidation(0);
          }, 4000);
        });

      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      reset();
      return;
    }
  };

  // Verificar si response.result existe antes de extraer links y data
  const result = response.result || {};
  const { data, ...links } = result;

  return (
    <div className="contact-us" id="contact-us">
      <h2>
        Ingresar URL de conversación
      </h2>
      {correctValidation !== 0 ? (
        <div className="message">
          <FormMessage correctValidation={correctValidation} />
        </div>
      ):(
        <span></span>
      )
      }
      <form onSubmit={handleSubmit} className="form-contact">
        <input
          className="input-name"
          type="text"
          name="url"
          id="url"
          value={formValues.url}
          onChange={handleInputChange}
          placeholder="URL"
          required
        />
        <br />
        <div className="checkbox-container">
          {loading ? (
              <button type="submit" disabled>
                <span
                  className="spinner-border spinner-border-md"
                  role="status"
                  aria-hidden="true"
                ></span>
                {"      "}
                En Proceso
              </button>
            ) : (
              <button 
              type='Submit'
              id="contactUs.button">
                Enviar
              </button>
            )}
        </div>
        <div>
          <Modal isOpen={isOpenModal} closeModal={closeModal}>
              <ModalHeader style={{display: 'block', borderBottom:'#fff'}}>
                  <span style={{float: 'right'}}></span>
              </ModalHeader>
              <ModalBody>
                <div className="checkGreen">
                  <img src={checkGreen} className="checkGreen-container" alt="checkGreen"/>
                </div>
                  <div>
                  {Object.keys(response).length !== 0 ? (
                    <div >
                      <h1 style={{textAlign:"left"}}>Enlaces de descarga</h1>
                      <ul style={{textAlign:"left"}}>
                          {Object.keys(links).map(key => (
                              <li key={key}>
                                  <a href={links[key]} target="_blank" rel="noopener noreferrer">
                                      {key !== "wav"?(<>{key.toUpperCase()}</>):(<>AUDIO</>)}
                                  </a>
                              </li>
                          ))}
                      </ul>
                      <h1 style={{textAlign:"left"}}>Resultado del análisis</h1>
                      <ul style={{textAlign:"left"}}>
                          {Object.keys(data).map(key => {
                              if (typeof data[key] === 'boolean') {
                                  return (
                                      <li key={key}>
                                          {key}: {booleanToText(data[key])}
                                      </li>
                                  );
                              } else if (typeof data[key] === 'object') {
                                  return (
                                      <li key={key}>
                                          <strong>{key}</strong>
                                          <ul>
                                              {Object.keys(data[key]).map(subKey => (
                                                  <li key={subKey}>
                                                      {subKey}: {booleanToText(data[key][subKey])}
                                                  </li>
                                              ))}
                                          </ul>
                                      </li>
                                  );
                              }
                              return null;
                          })}
                      </ul>
                    </div>
                  ):(<span>Esperando respuesta</span>)}
                </div>
              </ModalBody>
          </Modal>
        </div>
      </form>
    </div>
  );
};
