import React from 'react'
import "../assets/css/home.css";
import background from "../assets/img/background.png"
import { FormAnalyzer } from './FormAnalyzer';

const Home = () => {
  return (
    <div className='home-sales'>
      <div>
      <img
        src={background}
        alt='Future'
          style={{
          display: "grid",
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "60%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          zIndex: "0",
        }}
        />
      </div>
        <div className='home-security-content'>
          <h1 className='home-security-title'>
            Master Analyzer
          </h1>
          <h1 className='home-security-sub-title'>
            SOMOS EL GPS DE LAS VENTAS.
          </h1>
          <FormAnalyzer/>
        </div>        
    </div>
  )
}

export default Home