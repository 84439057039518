import React from 'react'
import "../assets/css/footer.css";

const Footer = () => {
  return (
    <div className='footer-content-position'>
      <h1 >Master-Analyzer</h1>
    </div>
  )
}

export default Footer