import React from 'react'
import "../assets/css/App.css"
import { Logo } from "../components/Logo.js";
import Footer from '../components/Footer';
import Login from '../components/Login.js';
import { Navigate } from 'react-router-dom';


const LoginScreen = () => {
  return (
    <div>
      {
        window.sessionStorage.getItem("token_user") === null?
        (
        <div className="fullPage">
            <div className="top-bar">
            <Logo />
            </div>
            <div className='home-security-position'>
            <Login/>
            </div>
            <div className='footer-position'>
            <Footer/>
            </div>
        </div>
        )
        :(<div>
          <Navigate to='/home'/>
      </div>)}
    </div>
  )
}

export default LoginScreen